/**
 * Created by razial on 27.03.2023
 */

TravelBook.PAGES = [
    {
        id: "main",
        prefix: "main",
        slot: CustomSyncers.SLOT_MAIN,
        episode: "0"
    }
];

TravelBook.SEASONAL_PAGES = [
    {
        id: "xmas",
        prefix: "xm",
        missionType: Mission.TYPE_XMAS_EXPEDITION,
        name: "XmasExpedition",
        episode: "xmas",
        noOrdersBuyout: true,
        available: {
            level: 7,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        },
        feature: "xmas_expedition",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_EXPEDITION_FEAST]
    },
    {
        id: "halloween",
        prefix: "hl",
        missionType: Mission.TYPE_HALLOWEEN_EXPEDITION,
        name: "HalloweenExpedition",
        episode: "halloween",
        noOrdersBuyout: true,
        feature: "halloween_expedition",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_EXPEDITION_FEAST]
    },
    {
        id: "easter",
        prefix: "es",
        missionType: Mission.TYPE_EASTER_EXPEDITION,
        name: "EasterExpedition",
        episode: "easter",
        feature: "easter_expedition",
        available: {
            level: 6,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        },
        energy: true,
        noOrdersBuyout: true,
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startFog: "fog1",
            rulesOptions: false
        }]
    },
    {
        id: "china",
        prefix: "ch",
        missionType: Mission.TYPE_CHINA_EXPEDITION,
        name: "ChinaExpedition",
        episode: "china",
        noOrdersBuyout: true,
        level: 0,
        feature: "china_expedition",
        available: {
            level: 6,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        },
        energy: true,
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startFog: "fog1",
            rulesOptions: false
        }]
    }
].map(function (page) {
    return Object.assign({
        slot: CustomSyncers.EXPEDITION_SLOT1,
        semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT1
    }, page);
});

TravelBook.ONESHOT_PAGES = [
    {
        id: "dragonia",
        prefix: "dr",
        missionType: Mission.TYPE_DRAGONIA_EXPEDITION,
        name: "DragoniaExpedition",
        episode: "dragonia",
        noOrdersBuyout: true,
        duration: "20 days",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_EXPEDITION_FEAST]
    },
    {
        id: "rapunzel",
        prefix: "rp",
        missionType: Mission.TYPE_RAPUNZEL_EXPEDITION,
        name: "RapunzelExpedition",
        episode: "rapunzel",
        duration: "6 days",
        noOrdersBuyout: true,
        energy: true,
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startFog: "fog1",
            rulesOptions: false
        }]
    },
    {
        id: "undersea",
        prefix: "sea",
        missionType: Mission.TYPE_UNDERSEA_EXPEDITION,
        name: "UnderseaExpedition",
        episode: "undersea",
        noOrdersBuyout: true,
        duration: "20 days",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_EXPEDITION_FEAST]
    },
    {
        id: "dragonia2",
        prefix: "dr2",
        missionType: Mission.TYPE_DRAGONIA2_EXPEDITION,
        name: "Dragonia2Expedition",
        episode: "dragonia",
        level: 1,
        noOrdersBuyout: true,
        available: {
            level: 7
        },
        duration: "20 days",
        energy: true,
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST]
    },
    {
        id: "rapunzel2",
        prefix: "rp2",
        missionType: Mission.TYPE_RAPUNZEL2_EXPEDITION,
        name: "Rapunzel2Expedition",
        episode: "rapunzel",
        level: 1,
        noOrdersBuyout: true,
        duration: "8 days",
        energy: true,
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startFog: "fog1",
            rulesOptions: false
        }]
    },
    {
        id: "undersea2",
        prefix: "sea2",
        missionType: Mission.TYPE_UNDERSEA2_EXPEDITION,
        name: "Undersea2Expedition",
        episode: "undersea",
        level: 1,
        noOrdersBuyout: true,
        parallelHeroDrop: true,
        duration: "20 days",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST]
    },
    {
        id: "dragonia3",
        prefix: "dr3",
        missionType: Mission.TYPE_DRAGONIA3_EXPEDITION,
        name: "Dragonia3Expedition",
        episode: "dragonia",
        level: 2,
        available: {
            level: 7,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        },
        duration: "20 days",
        energy: true,
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST]
    },
    {
        id: "rapunzel3",
        prefix: "rp3",
        missionType: Mission.TYPE_RAPUNZEL3_EXPEDITION,
        name: "Rapunzel3Expedition",
        episode: "rapunzel",
        level: 2,
        duration: "14 days",
        energy: true,
        available: {
            level: 7,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        },
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startUnit: { code: "rpcustomermain0a", stage: 1 },
            rulesOptions: false
        }]
    },
    {
        id: "undersea3",
        prefix: "sea3",
        missionType: Mission.TYPE_UNDERSEA3_EXPEDITION,
        name: "Undersea3Expedition",
        episode: "undersea",
        level: 2,
        available: {
            level: 7,
            projectName: ["mergecraft"]
        },
        duration: "20 days",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST]
    },
    {
        id: "adventure",
        prefix: "adv",
        missionType: Mission.TYPE_ADVENTURE_EXPEDITION,
        name: "AdventureExpedition",
        episode: "adventure",
        available: {
            level: 7.5,
            projectName: ["mergecraft"]
        },
        duration: "7 days",
        energy: true,
        disableNew: true,
        slot: CustomSyncers.EXPEDITION_SLOT4,
        semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4
    },
    {
        id: "adventure2",
        prefix: "adv2",
        missionType: Mission.TYPE_ADVENTURE2_EXPEDITION,
        name: "Adventure2Expedition",
        episode: "adventure",
        level: 1,
        available: {
            level: 7.5,
            projectName: ["mergecraft"],
            debugMode: true
        },
        duration: "7 days",
        energy: true,
        slot: CustomSyncers.EXPEDITION_SLOT4,
        semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4
    }
].map(function (page) {
    return Object.assign({
        oneShot: true,
        slot: CustomSyncers.EXPEDITION_SLOT2,
        semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT2
    }, page);
});

TravelBook.PERMANENT_PAGES = [
    {
        id: "collections",
        prefix: "cl",
        name: "CollectionsExpedition",
        episode: "collections",
        slot: CustomSyncers.EXPEDITION_SLOT3,
        permanent: true,
        gameLevel: true,
        noOrdersBuyout: true,
        available: {
            level: 8.1,
            projectName: ["mergecraft", "fairy", "wondermerge"]
        }
    }
];

TravelBook.PAGES = TravelBook.PAGES.concat(TravelBook.SEASONAL_PAGES, TravelBook.ONESHOT_PAGES, TravelBook.PERMANENT_PAGES);

TravelBook.findPageById = function (expedition) {
    for (var i = 0; i < TravelBook.PAGES.length; ++i) {
        var page = TravelBook.PAGES[i];
        if (page.id === expedition) {
            return page;
        }
    }
};

TravelBook.LIST_IN_ORDER_EXPEDITIONS = function () {
    return TravelBook.PAGES.filter(function (page) {
        return page.id !== "main";
    }).map(function (page) {
        return page.missionType;
    });
};

TravelBook.INIT_EXPEDITIONS = function () {
    var ExpeditionMissionData = Object.assign({}, {
        level: 0,
        duration: "1 day",
        logic: ExpeditionMissionLogic,
        rewardShareId: "bonus_world",
        sideBarJson: false,
        rewardTitle: "ExpeditionRewardWindow.title",
        rewardText: "ExpeditionRewardWindow.description"
    });

    TravelBook.PAGES.filter(function (page) {
        return page.missionType !== undefined;
    }).forEach(function (page) {
        page.available = page.available || cleverapps.Availables.EXPEDITIONS;
        Missions[page.missionType] = Object.assign({}, ExpeditionMissionData, page);
    });
};

if (typeof cc === "undefined") {
    module.exports = TravelBook;
}
