/**
 * Created by Denis Kuzin on 15 february 2021
 */

PlayButtonView.prototype.animatePassLevel = function () {
    if (this.button) {
        this.button.stopAllActions();
    }
    this.stopAllActions();

    this.runAction(new cc.Sequence(
        new cc.DelayTime(1.15),
        new cc.CallFunc(function () {
            this.styling = this.getStyling();

            this.button.setString(this.getTextMsg());

            this.button.setType(this.styling.type);
        }.bind(this))
    ));
};