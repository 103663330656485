/**
 * Created by iamso on 11/02/20
 */

var ScoreRocket = Rocket.extend({
    ctor: function (startPos, targetPos) {
        cc.Node.prototype.ctor.call(this);

        var styles = cleverapps.styles.DiscoverRocket;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);
        this.setPositionRound(startPos);
        var animation = this.animation = new cleverapps.Spine(bundles.game.jsons.diff_fly_json);

        animation.setAnimation(0, "animation", false);
        animation.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(animation);

        this.prevPos = startPos;
        this.scheduleUpdate();

        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, targetPos),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.ScoreRocket = {
    width: 100,
    height: 100
};