/**
 * Created by slava on 23.03.17.
 */

var EditorScene = GameSceneBase.extend({
    ctor: function (level) {
        this._super({
            level: level
        });
    },

    onSceneLoaded: function () {
        var level = this.level;

        connector.ads.closeStickyCallback();
        cleverapps.resolution.setContainerPadding();

        if (cleverapps.config.editorMode) {
            cleverapps.environment.setScene(cleverapps.Environment.SCENE_EDITOR);
        }

        LevelManager.getInstance().updateLoadedLevel(level);

        var Orangery;
        if (cleverapps.config.type === "merge") {
            this.game = new Game(level);
            Orangery = MergeOrangery;
        } else if (cleverapps.home) {
            this.game = new Homefix(level);
            Orangery = HomefixOrangery;
        } else {
            this.game = new Game(level);
        }

        cleverapps.FixedWidthScene.prototype.onSceneLoaded.call(this, cleverapps.Environment.SCENE_EDITOR);

        this.editor = new Editor(Orangery);

        var editorView = new EditorView(this.editor);
        this.addChild(editorView);

        if (typeof EditorController !== "undefined") {
            new EditorController(this.editor, this, editorView);
        }

        this.editor.load();

        var orangery = Editor.currentEditor.orangery;
        if (orangery) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.meta.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }
    },

    _closeAction: function () {
        cleverapps.setUrlHash({ mode: undefined });

        if (cleverapps.config.adminMode) {
            cleverapps.config.editorMode = false;
            AdministratorScene.open();
            return;
        }

        if (cleverapps.config.wysiwygMode) {
            cleverapps.config.editorMode = false;
            WysiwygScene.open(cleverapps.wysiwyg);
            return;
        }

        cleverapps.FixedWidthScene.prototype._closeAction.call(this);
    },

    initPool: function () {
    },

    saveCellSkin: function () {
        this.level.content.cellSkins = this.editor.cellSkins;
    },

    addSideBar: function () {
        if (cleverapps.config.type === "merge") {
            this._super();
        }
    },

    getBackgroundStyles: function () {
        if (cleverapps.config.type === "merge") {
            return MergeScene.prototype.getBackgroundStyles.apply(this, arguments);
        }

        if (["merge", "tile3"].indexOf(cleverapps.config.type) !== -1) {
            return;
        }

        return this._super();
    },

    listBundles: function () {
        var base;

        if (typeof HomefixScene !== "undefined") {
            base = this.level ? HomefixScene.prototype.listBundles.call(this) : [];
        } else {
            base = this.level ? GameScene.prototype.listBundles.call(this) : [];
        }

        var all = Editor.BUNDLES.filter(function (bundle) {
            return bundles[bundle];
        });

        return cleverapps.unique(base.concat(all).concat(["editor"]));
    }
});

EditorScene.open = function (options) {
    var level;
    if (options.level) {
        level = options.level;
    } else {
        LevelManager.getInstance().setLevel({ episodeNo: options.episodeNo, levelNo: options.levelNo });
        level = LevelManager.getInstance().level;
    }

    cleverapps.setUrlHash({ mode: "editor" });

    cleverapps.config.editorMode = true;
    cleverapps.config.adminMode = true;

    cleverapps.adsLimits.updateVolume();

    var page = cleverapps.travelBook.getPageById(level.meta.expedition) || cleverapps.travelBook.getPageById("main");
    cleverapps.travelBook.setCurrentPage(page);

    if (options.callback) {
        cleverapps.scenes.replaceScene(new EditorScene(level), options.callback);
    } else {
        cleverapps.meta.display({
            focus: "EditorSceneOpen",
            action: function (f) {
                cleverapps.scenes.replaceScene(new EditorScene(level), f);
            }
        });
    }
};
