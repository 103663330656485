/**
 * Created by iamso on 16.07.20.
 */

var BrushButtonBooster = DiscoverButtonBooster.extend({
    ctor: function (booster) {
        BaseButtonBooster.prototype.ctor.call(this, {
            booster: booster,
            styles: cleverapps.styles.BrushButtonBooster
        });

        booster.onAfterExecuted = this.createListener(this.afterExecuted.bind(this));
    },

    afterExecuted: function () {
        this.setScale(this.baseScale);
        this.disable();

        cleverapps.tooltipManager.create(this, {
            text: Messages.get("BrushBooster.tooltip"),
            location: cleverapps.UI.Tooltip.LOCATION_ABOVE
        });
    },

    enable: function () {
        if (this.booster.used) {
            return;
        }

        this._super.apply(this, arguments);
    },

    createIcon: function () {
        return new cc.Sprite(bundles.game.frames.brush_booster_icon);
    }
});

cleverapps.styles.BrushButtonBooster = {
    size: [{ width: 240, height: 100 }, { width: 180, height: 160 }, { width: 240, height: 100 }],
    zOrder: 0,

    type: cleverapps.styles.UI.Button.Images.button_blue,

    phone: {
        scale: 0.8
    },

    content: {
        freeBlock: [{ textWidth: 110 }, { textWidth: 120 }, { textWidth: 110 }],
        iconScale: [0.55, 0.7, 0.55],
        margin: [0, -8, 0],
        direction: [cleverapps.UI.HORIZONTAL, cleverapps.UI.VERTICAL, cleverapps.UI.HORIZONTAL],
        layout: [
            { x: { align: "center", dx: -5 }, y: { align: "center", dy: 0 } },
            { x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 } },
            { x: { align: "center", dx: -5 }, y: { align: "center", dy: 0 } }
        ]
    }
};