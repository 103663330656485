/**
 * Created by iamso on 13.07.2020.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    HINT_ALARM_COLOR: new cc.Color(150, 150, 150, 255),
    DARK_BROWN: new cc.Color(80, 60, 35, 255),
    LIGHT_BROWN: new cc.Color(157, 71, 57),
    DARK_GRAY: new cc.Color(77, 74, 72)
});

cleverapps.overrideFonts(cleverapps.styles.DECORATORS, {
    REWARDS_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.DARK_BROWN,
        size: 3
    },
    WHITE_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 2
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVEL_TITLE_TEXT: {
        name: "default",
        size: 45
    },

    LEVEL_CURRENT_TITLE_TEXT: {
        size: 45,
        name: "default"
    },

    LEVEL_NOT_PASSED_TITLE_TEXT: {
        size: 45,
        name: "default"
    },
    CLOVERS_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.DARK_BROWN
    },

    REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.REWARDS_TEXT_STROKE
    },

    BACKGROUNDS_WINDOW_TAPE_TEXT: {
        size: 33,
        color: cleverapps.styles.COLORS.WHITE
    },
    TOURNAMENT_PLAYER_TEXT: {
        size: 23,
        color: cleverapps.styles.COLORS.WHITE
    }

});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 200,
    height: 82,

    icon: {
        x: { align: "left", dx: -10 },
        width: 80
    },

    position: [
        {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -140 }
        },
        {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -140 }
        },
        {
            x: { align: "left", dx: 40 },
            y: { align: "top", dy: -140 }
        }
    ]

});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        posVert: {
            factorX: 0.5,
            factorY: 0.8
        },
        pos: {
            factorY: 0.7
        }
    },
    customAnimation: {
        posVert: {
            x: { align: "center", dx: -45 },
            y: { align: "center", dy: -70 }
        },
        pos: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "default": {
            idleDelay: -0.3,
            dx: 0,
            dy: 0,
            idleAnimation: "idle",
            introAnimation: "open"
        }
    },

    rewards: {
        delay: 2.15,
        duration: 0.475,
        fitTo: {
            width: 166
        },

        positions: {
            "default": {
                "boosters": {
                    8: {
                        x: -206,
                        y: -194
                    },
                    9: {
                        x: 197,
                        y: -195
                    }
                },
                "hard": {
                    x: -5,
                    y: -85
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ProgressView, {
    arrows: {
        y: { align: "center", dy: 6 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LotteryIcon, {
    scale: 0.9,
    positions: {
        "bottom": {
            y: { align: "bottom", dy: -50 }

        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GridPlayerNameView, {
    offsetY: 90
});

cleverapps.overrideStyles(cleverapps.styles.MinimalDialogue, {
    maxWidth: 1500
});