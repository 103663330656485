var CoolDownNode = cc.Node.extend({
    ctor: function (duration, warningsLeft, booster, closeCallback) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.resolution.getSceneSize().width, cleverapps.styles.CoolDownNode.height);

        this.closeCallback = closeCallback;
        this.duration = duration;
        this.warningsLeft = warningsLeft;
        this.discoverBooster = booster;

        this.createContent();
        this.createBg();
    },

    showUp: function (f) {
        var scene = cleverapps.scenes.getRunningScene();

        var start = cc.p(scene.width / 2, scene.height + this.height / 2);
        var target = cc.p(scene.width / 2, scene.height / 2);

        this.setPositionRound(start);

        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, target).easing(cc.easeBackOut(1)),
            new cc.CallFunc(f)
        ));
    },

    animateClose: function (f) {
        var scene = cleverapps.scenes.getRunningScene();
        var finish = cc.p(scene.width / 2, -this.height / 2);

        this.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, finish).easing(cc.easeBackIn(1)),
            new cc.RemoveSelf(),
            new cc.CallFunc(f)
        ));
    },

    createBg: function () {
        var styles = cleverapps.styles.CoolDownNode;
        var sceneSize = cleverapps.resolution.getSceneSize();
        var spriteWidth = cleverapps.UI.createScale9Sprite(bundles.gamemessage.frames.gamemessage_bg_png).width;

        var sprites = [];
        for (var i = 0; i < Math.ceil(sceneSize.width / (spriteWidth + styles.pattherMargin)); i++) {
            var sprite = cleverapps.UI.createScale9Sprite(bundles.gamemessage.frames.gamemessage_bg_png);
            sprite.setContentSize(sprite.width, this.content.height);
            sprites.push(sprite);
        }

        var bg = new cleverapps.Layout(sprites, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.pattherMargin
        });

        this.addChild(bg, -1);
        bg.setPositionRound(this.width / 2, this.height / 2);
    },
    
    createContent: function () {
        var styles = cleverapps.styles.CoolDownNode;
        var timer = this.createTimer();
        var text = cleverapps.UI.generateTTFText("CoolDownNode.text", cleverapps.styles.FONTS.WINDOW_TEXT, {
            warnings: this.warningsLeft
        });

        text.fitTo(this.width * 0.8);
        var items = [text, timer];
        if (this.discoverBooster) {
            items.push(this.createBuyButton());
        }

        var content = this.content = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this.addChild(content);
        content.setPositionRound(styles.content);
    },

    createTimer: function () {
        var countdown = new cleverapps.CountDown(this.duration, {
            onFinish: this.closeCallback,
            onTick: function (timeLeft) {
                cleverapps.audio.playSound(bundles.game.urls[Math.ceil(timeLeft / 1000) % 2 === 0 ? "timer_tick_1" : "timer_tick_2"]);
            }
        });

        var bg = cleverapps.UI.createScale9Sprite(bundles.game.frames.timer_concave_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(cleverapps.styles.CoolDownNode.timer);

        this.countView = new cleverapps.CountDownView(countdown, {
            showHours: false,
            font: cleverapps.styles.FONTS.COOLDOWNNODE_TIMER_TEXT,
            background_content: bg
        });
        return this.countView;
    },

    createBuyButton: function () {
        var styles = cleverapps.styles.DiscoverButtonBooster;

        var price = this.discoverBooster.getPrice();
        var amount = this.discoverBooster.getAmount();

        var icon = new cleverapps.Spine(bundles.game.jsons.discovery_icon_json);
        icon.setAnimation(0, "idle", true);
        icon.setScale2(styles.content.iconScale);

        var text;

        if (amount > 0) {
            text = cleverapps.UI.generateImageText("x" + amount, cleverapps.styles.FONTS.AMOUNT_BOOSTER);
        } else {
            text = new TextWithIcon(price.soft ? "@@" + price.amount : "$$" + price.amount, {
                font: cleverapps.styles.FONTS.BUTTON_BOOSTER
            });
        }

        var layout = new cleverapps.Layout([icon, text], {
            direction: styles.content.direction[cleverapps.resolution.mode],
            margin: styles.content.margin[cleverapps.resolution.mode]
        });
        layout.setPositionRound(styles.content.layout);

        var content = new cc.Node();
        content.addChild(layout);

        var size = styles.size[cleverapps.resolution.mode];

        var button = new cleverapps.UI.Button({
            content: content,
            width: size.width,
            height: size.height,
            onClicked: function () {
                if (this.discoverBooster.canExecute() || this.discoverBooster.buy()) {
                    this.discoverBooster.execute();
                    this.closeCallback();
                }
            }.bind(this)
        });
        button.alwaysOn = true;
        return button;
    }
});

cleverapps.styles.CoolDownNode = {
    height: 460,
    margin: 15,
    pattherMargin: 0,
    padding: { x: 0, y: 50 },

    timer: {
        width: 260,
        height: 100
    },

    button: {
        x: { align: "center" },
        y: { align: "center" }
    },

    content: {
        x: { align: "center" },
        y: { align: "center", dy: 0 }
    }
};