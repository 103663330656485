/**
 * https://firebase.google.com/docs/remote-config/get-started?platform=web
 *
 * Created by andrey on 12.08.2024
 */

var RemoteConfig = function (config) {
    this.config = config;

    if (config.remote) {
        this.key = config.remote.split("@")[0];
        this.parameter = config.remote.split("@")[1];

        RemoteConfigManager.add(this);
    }
};

RemoteConfig.prototype.getConfig = function () {
    return this.config;
};

RemoteConfig.prototype.setConfig = function (config) {
    console.log("setConfig", this, config);
    this.config = config;
};

var RemoteConfigManager = {
    firebaseConfigs: {},

    add: function (remoteConfig) {
        if (!RemoteConfigManager.isAvailable()) {
            return;
        }

        if (!this.connected) {
            this.connect(function () {
                RemoteConfigManager.add(remoteConfig);
            });
            return;
        }

        var key = remoteConfig.key;

        var firebaseConfig = this.firebaseConfigs[key];

        if (!firebaseConfig) {
            var firebaseKey = cleverapps.config["remote_" + key];
            if (!firebaseKey) {
                return;
            }

            this.firebaseConfigs[key] = firebaseConfig = firebase.initializeApp(firebaseKey, key).remoteConfig();
            firebaseConfig.defaultConfig = {};
            firebaseConfig.remoteConfigs = [];

            if (cleverapps.config.debugMode) {
                firebaseConfig.settings.minimumFetchIntervalMillis = 1000;
            }
        }

        var defaultConfig = remoteConfig.getConfig();
        if (typeof defaultConfig === "object") {
            defaultConfig = JSON.stringify(defaultConfig);
        }

        firebaseConfig.defaultConfig[remoteConfig.parameter] = defaultConfig;
        firebaseConfig.remoteConfigs.push(remoteConfig);
    },

    connect: function (callback) {
        if (!this.connectListeners) {
            this.connectListeners = [];
        }

        this.connectListeners.push(callback);

        if (this.connecting) {
            return;
        }
        this.connecting = true;

        var player = new ActionPlayer([
            function (f, stop) {
                cleverapps.loadSdk("https://www.gstatic.com/firebasejs/8.10.1/firebase-app.js", { onSuccess: f, onFailure: stop });
            },

            function (f, stop) {
                cleverapps.loadSdk("https://www.gstatic.com/firebasejs/8.10.1/firebase-remote-config.js", { onSuccess: f, onFailure: stop });
            },

            function (f, stop) {
                if (typeof firebase === "undefined") {
                    stop();
                } else {
                    f();
                }
            }
        ]);

        player.onComplete(function () {
            this.connecting = false;
        }.bind(this));

        player.play(this.setConnected.bind(this));
    },

    setConnected: function () {
        this.connected = true;

        this.connectListeners.forEach(cleverapps.callFunc);
        delete this.connectListeners;

        cc.eventManager.addCustomListener(cc.game.EVENT_SHOW, function () {
            this.fetchAndActivate();
        }.bind(this));

        this.fetchAndActivate();
    },

    fetchAndActivate: cleverapps.accumulate(1000, cleverapps.throttle(10000, function () {
        var size = Object.keys(this.firebaseConfigs).length;
        if (!size) {
            return;
        }

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_START);

        var start = Date.now();

        var onSuccess = cleverapps.wait(size, function () {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_SUCCESS);
            console.log("RemoteConfig fetch success - " + (Date.now() - start) + "ms");
        });

        var onFailure = cleverapps.once(function () {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.CONFIG.FETCH_FAILURE);
        });

        Object.values(this.firebaseConfigs).forEach(function (firebaseConfig) {
            firebaseConfig.fetchAndActivate()
                .then(function () {
                    this.activateValues(firebaseConfig);
                    onSuccess();
                }.bind(this))
                .catch(function (e) {
                    console.log("RemoteConfig fetch error", e);
                    onFailure();
                });
        }.bind(this));
    })),

    activateValues: function (firebaseConfig) {
        firebaseConfig.remoteConfigs.forEach(function (remoteConfig) {
            var value = firebaseConfig.getValue(remoteConfig.parameter);
            value = value && value.asString();

            if (value && remoteConfig.parameter.endsWith("_json")) {
                value = JSON.parse(value);
            }

            remoteConfig.setConfig(value);
        });
    },

    isAvailable: function () {
        return !cleverapps.flags.norest && !connector.platform.oneOf(connector.DISCORD);
    }
};
