/**
 * Created by iamso on 20.07.2020.
 */

KnockoutPlayersView.prototype.setupChildren = function () {
    if (this.top === undefined) {
        this.top = this.userView;
        this.bottom = this.opponentView;
    }
    var styles = cleverapps.styles.KnockoutPlayersView;

    var sceneSize = cleverapps.resolution.getSceneSize();
    var padding = cleverapps.styles.GameScene.padding;

    if ((this.width + padding) > sceneSize.width) {
        this.setScale(sceneSize.width / (this.width + padding));
    }

    this.setPositionRound(styles.position);
    this.top.setPositionRound(styles.views.left);
    this.bottom.setPositionRound(styles.views.right);
};

KnockoutPlayersView.prototype.hide = function () {
    this.stopAllActions();
    this.runAction(new cc.Sequence(
        new cc.DelayTime(0.1),
        new cc.ScaleTo(0.3, 0).easing(cc.easeBackIn()),
        new cc.Hide()
    ));
};

KnockoutPlayersView.prototype._resultsChanged = KnockoutPlayersView.prototype.resultsChanged;
KnockoutPlayersView.prototype.resultsChanged = function () {
    if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
        this.competition.calcPlace();

        var views = [this.userView, this.opponentView];
        views.sort(function (v1, v2) {
            return this.competition.results.indexOf(v1.player) - this.competition.results.indexOf(v2.player);
        }.bind(this));

        this.setViewsOrder(views);
    }

    this._resultsChanged.apply(this, arguments);
};

KnockoutPlayersView.prototype.setViewsOrder = function (views) {
    var top = this.top = views[0];
    var bottom = this.bottom = views[1];

    if (top.y < bottom.y) {
        top.setLocalZOrder(1);
        bottom.setLocalZOrder(0);

        top.stopAllActions();
        bottom.stopAllActions();

        top.runAction(new cc.Spawn(
            new cc.CallFunc(function () {
                top.setLocalZOrder(top.getLocalZOrder() + 1);
            }),
            new cc.Sequence(
                new cc.ScaleTo(0.2, 1.1),
                new cc.DelayTime(0.1),
                new cc.ScaleTo(0.2, 1)
            ),
            new cc.MoveTo(0.5, bottom.x, bottom.y),
            new cc.CallFunc(function () {
                top.setLocalZOrder(top.getLocalZOrder() + 1);
            })
        ));

        bottom.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.ScaleTo(0.2, 0.9),
                new cc.DelayTime(0.1),
                new cc.ScaleTo(0.2, 1)
            ),
            new cc.MoveTo(0.5, top.x, top.y)
        ));
    }
};

KnockoutPlayersView.prototype.createBg = function () {};

KnockoutPlayersView.prototype.createWarning = function () {};

KnockoutPlayersView.prototype.toggleWarning = function () {};

cleverapps.overrideStyles(cleverapps.styles.KnockoutPlayersView, {
    warning: {
        x: { align: "center" },
        y: { align: "center", dy: 3 }
    },

    views: {
        left: [{
            x: { align: "left" },
            y: { align: "center" }
        }, {
            x: { align: "center" },
            y: { align: "top" }
        }, {
            x: { align: "left" },
            y: { align: "center" }
        }],
        right: [{
            x: { align: "right" },
            y: { align: "center" }
        }, {
            x: { align: "center" },
            y: { align: "bottom" }
        }, {
            x: { align: "right" },
            y: { align: "center" }
        }]
    },

    size: [
        { width: 800, height: 115 },
        { width: 430, height: 260 },
        { width: 890, height: 115 }
    ],

    position: [{
        x: { align: "center" },
        y: { align: "top", dy: -140 }
    }, {
        x: { align: "left" },
        y: { align: "center", dy: 200 }
    }, {
        x: { align: "center" },
        y: { align: "top", dy: -160 }
    }],

    finger: false,
    bgOffset: 0
});