/**
 * Created by iamso on 23.07.2020.
 */

KnockoutPlayerView.prototype._superCtor = KnockoutPlayerView.prototype.ctor;
KnockoutPlayerView.prototype.ctor = function () {
    this._superCtor.apply(this, arguments);
    
    this.createName();
    this.addPlace();
};

KnockoutPlayerView.prototype._checkPlayerPointOpened = KnockoutPlayerView.prototype.checkPlayerPointOpened;
KnockoutPlayerView.prototype.checkPlayerPointOpened = function () {
    this._checkPlayerPointOpened.apply(this, arguments);

    [this.placeIcon, this.place].forEach(function (item) {
        item.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.4, item.baseScale * 1.4).easing(cc.easeOut(3)),
                new cc.Sequence(
                    new cc.RotateTo(0.15, 15),
                    new cc.RotateTo(0.15, -15),
                    new cc.RotateTo(0.1, 0)
                )
            ),
            new cc.ScaleTo(0.15, item.baseScale)
        ));
    });
    this.place.setString(this.player.amount + "");
};

KnockoutPlayerView.prototype._createAvatar = KnockoutPlayerView.prototype.createAvatar;
KnockoutPlayerView.prototype.createAvatar = function () {
    if (this.player) {
        this.player.frame = bundles.game.frames[this.player.player ? "avatar_frame_me" : "avatar_frame_rival"];
    }

    this._createAvatar.apply(this, arguments);
};

KnockoutPlayerView.prototype.createName = function () {
    var name = cleverapps.UI.generateTTFText(this.player.name.split(" ")[0], cleverapps.styles.FONTS.TOURNAMENT_PLAYER_TEXT);
    name.fitTo(cleverapps.styles.KnockoutPlayerView.name.width);
    this.addChild(name, 1);
    name.setPositionRound(cleverapps.styles.KnockoutPlayerView.name);
};

KnockoutPlayerView.prototype.addPlace = function () {
    var placeIcon = this.placeIcon = new cc.Sprite(bundles.game.frames[this.player.player ? "place_icon_me" : "place_icon_rival"]);
    this.addChild(placeIcon);
    placeIcon.setPositionRound(cleverapps.styles.KnockoutPlayerView.place.icon[this.pos]);
    placeIcon.baseScale = placeIcon.scale;

    this.place = cleverapps.UI.generateImageText(this.player.amount, cleverapps.styles.FONTS.PLAYER_PLACE);
    this.place.baseScale = this.place.scale;
    this.addChild(this.place);
    this.place.setPositionRound(cleverapps.styles.KnockoutPlayerView.place[this.pos]);
};

KnockoutPlayerView.prototype.createBg = function () {
    var bg = this.bg = cleverapps.UI.createScale9Sprite(bundles.game.frames[this.player.player ? "player_bg" : "opponent_bg"], cleverapps.UI.Scale9Rect.TwoPixelXY);
    this.addChild(bg, -1);
};

KnockoutPlayerView.prototype.getSize = function () {
    return cleverapps.styles.KnockoutPlayerView.size[cleverapps.resolution.mode];
};

KnockoutPlayerView.prototype.createPoints = function () {
    var styles = cleverapps.styles.KnockoutPlayerView.points;
    var game = Game.currentGame;

    this.points = [];
    for (var i = 0; i < game.totalItemsToSolve(); i++) {
        this.points.push(new cc.Sprite(styles[this.pos].image));
    }

    this.pointsLayout = new cleverapps.Layout(this.points, {
        direction: cleverapps.UI.HORIZONTAL,
        margin: styles.margin
    });

    this.addChild(this.pointsLayout);
    this.pointsLayout.setupChildren = function () {
        var scaleX = styles.size[cleverapps.resolution.mode].maxWidth / this.pointsLayout.width;
        this.pointsLayout.setScale(Math.min(1, scaleX));
        this.pointsLayout.setPositionRound(styles[this.pos]);
    }.bind(this);
    this.pointsLayout.setupChildren();

    if (!this.player.savedPoints) {
        for (i = 0; i < this.player.amount; i++) {
            this.openScorePoint(true);
        }
    }
};

KnockoutPlayerView.prototype.addFrame = function () {};

KnockoutPlayerView.prototype._runFrameAnimation = function () {
    if (!this.userBehindRunning) {
        this.setColor(cc.color(255, 255, 255, 255));
        return;
    }

    this.bg.runAction(
        new cc.Sequence(
            new cc.TintTo(0.65, 255, 180, 180),
            new cc.TintTo(0.65, 255, 255, 255),
            new cc.DelayTime(0.3),
            new cc.CallFunc(this._runFrameAnimation.bind(this))
        )
    );
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PLAYER_PLACE: {
        name: "nostroke",
        size: 35
    }
});

cleverapps.overrideStyles(cleverapps.styles.KnockoutPlayerView, {
    size: [
        { width: 390, height: 95 },
        { width: 400, height: 95 },
        { width: 430, height: 95 }
    ],

    freeze: {
        width: 430
    },

    place: {
        "left": {
            x: { align: "right", dx: -18 },
            y: { align: "top", dy: 6 }
        },
        "right": {
            x: { align: "right", dx: -18 },
            y: { align: "top", dy: 6 }
        },

        icon: {
            "left": {
                x: { align: "right", dx: -48 },
                y: { align: "top", dy: -4 }
            },
            "right": {
                x: { align: "right", dx: -48 },
                y: { align: "top", dy: -4 }
            }
        }
    },

    name: {
        width: 150,
        x: { align: "left", dx: 114 },
        y: { align: "center", dy: 30 }
    },

    avatar: {
        scale: 1.15,
        "left": {
            x: { align: "left", dx: 0 },
            y: { align: "center", dy: 3 }
        },
        "right": {
            x: { align: "left", dx: 0 },
            y: { align: "center", dy: 3 }
        }
    },

    points: {
        size: [
            { maxWidth: 220 },
            { maxWidth: 270 },
            { maxWidth: 270 }
        ],

        "left": {
            x: { align: "left", dx: 114 },
            y: { align: "center", dy: -24 }
        },
        "right": {
            x: { align: "left", dx: 114 },
            y: { align: "center", dy: -24 }
        }
    },

    score: false
});