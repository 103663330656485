/**
 * Created by andrey on 03.08.17.
 */

var Level = function (episodeNo, levelNo, options) {
    options = options || {};

    if (arguments.length === 0) {
        return;
    }

    this.episodeNo = episodeNo;
    this.levelNo = levelNo;

    var bundle = bundles[this.bundleId()];
    this.meta = Object.assign({}, bundle.meta.episode.levels && bundle.meta.episode.levels[levelNo] || {});

    if (!this.meta.expedition) {
        this.meta.expedition = "main";
    }

    if (cleverapps.config.type === "merge") {
        this.families = Families.listExpeditionCodes(this.meta.expedition);
    }

    this.loadTasks = {};
    this.episodeType = options.type ? options.type : Episode.CalcType(episodeNo);

    this.update();
};

Level.prototype.isInteractive = function () {
    return true;
};

Level.prototype.bundleId = function () {
    return Episode.BundleId(this.episodeNo);
};

Level.prototype.isAvailableToPlay = function () {
    if (cleverapps.config.editorMode) {
        return true;
    }

    if (cleverapps.meta.getType() === Metha.HOSE) {
        return levels.user.episode >= this.episodeNo;
    }

    return true;
};

Level.prototype.isClickable = function () {
    if (cleverapps.config.editorMode) {
        return true;
    }

    if (this.type === Level.TYPE_NOTPASSED || !this.isAvailableToPlay()) {
        return false;
    }

    if (this.type === Level.TYPE_CURRENT) {
        return true;
    }

    return this.isCurrentLevel();
};

Level.prototype.getHumanReadableNumber = function () {
    return cleverapps.humanReadableNumber(this.episodeNo, this.levelNo);
};

// will be overridden in DailyCup & BonusRound
Level.prototype.isRegular = function () {
    return this.episodeType === Episode.Types.REGULAR;
};

Level.prototype.isDailyCup = function () {
    return this.episodeType === Episode.Types.DAILYCUP;
};

Level.prototype.isBonusRound = function () {
    return this.episodeType === Episode.Types.BONUSROUNDS;
};

Level.prototype.isDailyLevel = function () {
    return this.episodeType === Episode.Types.DAILY_LEVELS;
};

Level.prototype.isBonusWorldLevel = function () {
    return this.episodeType === Episode.Types.BONUS_WORLD;
};

Level.prototype.isAdsLevel = function () {
    return this.episodeType === Episode.Types.ADS || this.type === Episode.Types.PROMO || this.type === Episode.Types.SCREENSHOTS;
};

Level.prototype.isCurrentLevel = function () {
    return this.isRegular() && levels.user.isCurrentLevel(this.levelNo, this.episodeNo);
};

Level.prototype.isPassedLevel = function () {
    return levels.user.isPassedLevel(this.levelNo, this.episodeNo);
};

Level.prototype.getTag = function () {
    return this.meta.tag;
};

Level.prototype.isHard = function () {
    return this.getTag() === Level.TAGS.HARD;
};

Level.prototype.isTricky = function () {
    return this.getTag() === Level.TAGS.TRICKY;
};

Level.prototype.isNewRules = function () {
    return this.getTag() === Level.TAGS.RULES;
};

Level.prototype.isBonus = function () {
    return this.getTag() === Level.TAGS.BONUS;
};

Level.prototype.getImageBundle = function () {
    return this.meta.bundleName;
};

Level.prototype.getLevelCellSkins = function () {
    return this.meta.cellSkins;
};

Level.prototype.getLevelEnemies = function () {
    var enemies = {};
    if (this.meta.enemies) {
        this.meta.enemies.forEach(function (type) {
            enemies[type] = Game.GOAL_ENEMY_TYPES[type];
        });
    }
    return enemies;
};

Level.prototype.isNew = function () {
    return this.type !== Level.TYPE_PASSED || this.isCurrentLevel();
};

Level.prototype.isLastLevelOnEpisode = function () {
    var bundle = bundles[this.bundleId()];
    var levelsPerEpisode = bundle.meta.episode.levelsAmount;
    return this.levelNo === levelsPerEpisode - 1;
};

Level.prototype.getLevelsContent = function (copyData) {
    if (this.levelsContent) {
        return this.levelsContent;
    }

    this.levelsContent = cc.loader.getRes(bundles[this.bundleId()].jsons.levels);

    if (copyData) {
        this.levelsContent = JSON.parse(JSON.stringify(this.levelsContent));
    }

    if (!this.levelsContent) {
        throw new Error("No content for episode " + this.episodeNo + " scene " + cleverapps.environment.scene
            + " loaded " + (this.loadTasks.levels && (this.loadTasks.levels.loaded + " " + this.loadTasks.levels.active))
            + " bundle " + this.bundleId() + " " + this.isLoaded());
    }

    return this.levelsContent;
};

Level.prototype.getLevelVersions = function () {
    var levelsContent = this.getLevelsContent();
    var episodeLevels = Array.isArray(levelsContent) ? levelsContent : levelsContent.levels;

    if (!episodeLevels || !episodeLevels[this.levelNo]) {
        cleverapps.throwAsync("No levels for episode " + this.episodeNo + " level " + this.levelNo);
    }

    var content = episodeLevels[this.levelNo];
    if (!Array.isArray(content)) {
        content = [content];
    }

    var versions = {};
    content.forEach(function (level, index) {
        var version = level.version || index;
        versions[version] = level;
    });
    return versions;
};

Level.prototype.getMaxVersion = function (versions) {
    var maxVersion = 0;
    for (var version in versions) {
        maxVersion = Math.max(maxVersion, parseInt(version));
    }
    return maxVersion;
};

Level.prototype.getLevelContent = function () {
    var versions = this.getLevelVersions();
    var activeVersion = this.getMaxVersion(versions);

    if (cleverapps.config.editorMode && LevelManager.getInstance().version !== undefined
        && !(cleverapps.environment.isMainScene() && !cleverapps.travelBook.isExpedition())) {
        activeVersion = LevelManager.getInstance().version;
    }

    var content = versions[activeVersion];

    content.episodeNo = this.episodeNo;
    content.levelNo = this.levelNo;
    content.version = activeVersion;
    return content;
};

Level.prototype.getVersion = function () {
    if (this.version === undefined) {
        this.version = this.getLevelContent().version;
    }
    return this.version;
};

Level.prototype.isLoaded = function () {
    return cleverapps.bundleLoader.isLoaded(this.bundleId());
};

Level.prototype.loadBundles = function (task, bundles, callback) {
    if (this.deleted) {
        callback && callback();
        return;
    }

    if (!this.loadTasks[task]) {
        this.loadTasks[task] = new SuccessWait(bundles);
    }

    this.loadTasks[task].whenLoaded(callback);
};

Level.prototype.loadData = function (callback) {
    if (this.isLoaded()) {
        callback && callback();
        return;
    }
    this.loadBundles("levels", [this.bundleId()], callback);
};

Level.prototype.destructor = function () {
    if (this.deleted) {
        return;
    }

    for (var taskName in this.loadTasks) {
        var task = this.loadTasks[taskName];
        task.destructor();
    }

    this.loadTasks = {};

    this.deleted = true;
};

Level.prototype.load = function (callback) {
    if (this.content && !cleverapps.config.editorMode) {
        callback && callback();
    } else {
        this.loadData(function () {
            this.content = this.getLevelContent();
            this.hash = Level.CalcHash(this.content);
            callback && callback(this);
        }.bind(this));
    }
};

Level.prototype.unload = function () {
    this.destructor();
};

Level.CalcHash = function (levelContent) {
    return RecursiveHasher(levelContent, ["episodeNo", "levelNo", "version", "hard", "paint", "extra", "gold", "hash", "hashes", "comments", "translate", "definitions", "bundles", "horizontalCards"]);
};

Level.prototype.play = function (f, options) {
    if (cleverapps.config.editorMode) {
        cleverapps.config.editorMode = false;
        this.content = undefined;
        cleverapps.environment.setScene(cleverapps.Environment.SCENE_GAME);
    }

    var scene = new GameScene({
        level: this,
        gameOptions: options
    });

    cleverapps.scenes.replaceScene(scene, f);
};

Level.prototype.onPressed = function () {
    if (cleverapps.lives && cleverapps.lives.isEmpty()) {
        cleverapps.meta.display({
            focus: ["LevelPressedNoLives", "MenuBarGoldItem", "MenuBarLivesItem"],
            action: function (f) {
                new LivesShopWindow();
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    } else if (cleverapps.config.features.includes("boosters_before") && !WindowBoostersBefore.isAvailable() && this.isCurrentLevel() && !this.isHard()
            || !LevelStartWindow.isAvailable(this)) {
        cleverapps.meta.display({
            focus: "LevelPressedPlay",
            action: function (f) {
                this.play(f);
            }.bind(this)
        });
    } else {
        cleverapps.meta.display({
            focus: "LevelStartWindow",
            control: "MenuBarGoldItem",
            actions: [
                function (f) {
                    new LevelStartWindow({
                        level: this
                    });
                    cleverapps.meta.onceNoWindowsListener = f;
                }.bind(this)
            ]
        });
    }
};

Level.prototype.update = function () {
    if (cleverapps.meta.getType() !== Metha.HOSE && cleverapps.meta.getType() !== Metha.SHORTMETA) {
        this.type = Level.TYPE_CURRENT;
        return;
    }

    var episodeNo = levels.user.episode;
    var levelNo = levels.user.level;

    if (cleverapps.meta.getType() === Metha.SHORTMETA) {
        episodeNo = Math.floor(cleverapps.meta.getMainObject().level / Episode.LEVELS_PER_EPISODE);
        levelNo = cleverapps.meta.getMainObject().level % Episode.LEVELS_PER_EPISODE;
    }

    if (episodeNo > this.episodeNo) {
        this.type = Level.TYPE_PASSED;
    } else if (episodeNo < this.episodeNo) {
        this.type = Level.TYPE_NOTPASSED;
    } else if (levelNo > this.levelNo) {
        this.type = Level.TYPE_PASSED;
    } else if (levelNo === this.levelNo) {
        this.type = Level.TYPE_CURRENT;
    } else {
        this.type = Level.TYPE_NOTPASSED;
    }

    this.stars = (this.type === Level.TYPE_PASSED) ? 3 : 0;
};

Level.prototype.nextLevel = function () {
    if (this.building) {
        return this;
    }

    var episodeNo = this.episodeNo;
    var levelNo = this.levelNo + 1;

    if (levelNo >= Episode.LEVELS_PER_EPISODE) {
        levelNo = 0;
        episodeNo += 1;
    }

    return MethaHelper.getLevel(episodeNo, levelNo);
};

Level.prototype.clone = function () {
    var level = new Level();
    Object.assign(level, this);
    return level;
};

Level.AvailableTags = function () {
    if (cleverapps.config.subtype === "stacks") {
        return [Level.TAGS.HARD, Level.TAGS.TRICKY, Level.TAGS.BONUS];
    }

    if (cleverapps.config.type === "board") {
        return [Level.TAGS.HARD, Level.TAGS.BONUS];
    }

    if (cleverapps.config.type === "match3") {
        return [Level.TAGS.RULES, Level.TAGS.HARD, Level.TAGS.TRICKY, Level.TAGS.BONUS];
    }

    return [Level.TAGS.HARD];
};

Level.CreateByNumber = function (x) {
    var episodeNo = Math.floor(x / Episode.LEVELS_PER_EPISODE);
    var levelNo = x % Episode.LEVELS_PER_EPISODE;

    var total = levels.User.CalcEpisodesAmount(cleverapps.config.importMode);
    if (episodeNo >= total) {
        episodeNo = total;
        levelNo = 0;
        var seed = x - cleverapps.humanReadableNumber(episodeNo, levelNo) + 1;

        return levels.user.getRandomLevel(seed);
    }

    return new Level(episodeNo, levelNo);
};

Level.TYPE_PASSED = 0;
Level.TYPE_CURRENT = 1;
Level.TYPE_NOTPASSED = 2;

Level.TAGS = {};
Level.TAGS.HARD = "hard";
Level.TAGS.TRICKY = "tricky";
Level.TAGS.BONUS = "bonus";
Level.TAGS.RULES = "rules";

if (typeof cc === "undefined") {
    module.exports = Level;
}
