/**
 * Created by Andrey Popov on 20.01.2021.
 */

var Wysiwyg = function (directory, silent) {
    this.assets = new Assets(this);
    this.hierarchy = new Hierarchy(this);
    this.wysiwygPreview = new WysiwygPreview(this);
    this.previewSlider = new PreviewSlider(this);
    this.inspector = new Inspector(this);
    this.wysiwygMenubar = new WysiwygMenubar(this);

    this.directory = directory || Wysiwyg.DEFAULT_DIRECTORY;
    this.googleDriveProvider = new GoogleDriveProvider();

    if (connector.info.source !== "playable" && !silent) {
        cleverapps.setUrlHash({
            directory: this.directory
        });
    }

    this.playable = this.directory.indexOf("playable") !== -1;
};

Wysiwyg.prototype.handleGitStatusChanged = function () {
    var selectedItem = this.hierarchy.selectedItem;
    if (selectedItem && !cleverapps.git.hasLocalChanges() && !cleverapps.git.hasRemoteChanges()) {
        if (selectedItem.type === HierarchyItem.TYPE.DIRECTORY) {
            this.hierarchy.loadDirectory(selectedItem.id);
        } else {
            this.hierarchy.loadClip(this.hierarchy.getSelectedClip().id);
        }
    }
};

Wysiwyg.prototype.addComponent = function (component, parentId, position) {
    var parentItem = this.hierarchy.items.filter(function (item) {
        return item.id === parentId;
    })[0];

    if (!parentItem || parentItem.type === HierarchyItem.TYPE.CLIP) {
        return;
    }

    var initialProperties = {
        position: position,
        scale: 1,
        rotation: 0,
        zOrder: 0,
        caption: component.preview.caption,
        visible: true
    };

    if (window[component.name] && window[component.name].PROPERTIES) {
        window[component.name].PROPERTIES.forEach(function (property) {
            if (typeof property.value === "function") {
                initialProperties[property.name] = property.value();
            } else if (property.value !== undefined) {
                initialProperties[property.name] = property.value;
            }
        });
    }

    var sceneComponent = new SceneComponent({ ctor: component.ctor, properties: initialProperties });
    this.wysiwygPreview.registerComponent(sceneComponent);

    var hierarchyItem = new HierarchyItem({
        type: HierarchyItem.TYPE.COMPONENT,
        parentItem: parentItem,
        id: sceneComponent.id,
        assetName: component.name,
        properties: initialProperties
    });
    this.hierarchy.addItem(hierarchyItem);
};

Wysiwyg.prototype.addScript = function (script, parentId) {
    var parentItem = this.hierarchy.items.filter(function (item) {
        return item.id === parentId;
    })[0];

    if (!parentItem || parentItem.type === HierarchyItem.TYPE.CLIP) {
        return;
    }

    var initialProperties = {
        visible: true,
        caption: script.name,
        scriptFileName: script.fileName
    };

    var hierarchyItem = HierarchyItem.createScript(script.fileName, parentItem, initialProperties);
    this.hierarchy.addItem(hierarchyItem);
};

Wysiwyg.prototype.previewVideo = function () {
    var params = cleverapps.getRequestParameters(location.hash);
    var clip = this.hierarchy.getSelectedClip();

    var url = window.location.origin + window.location.pathname + "#directory=" + params.directory
        + "&clipId=" + params.clipId + "&lang=" + clip.properties.languages[0]
        + "&res=" + this.wysiwygPreview.selectedResolution;

    var snapshot = clip.children[0] && this.hierarchy.getSceneSnapshot(clip.children[0]);
    url += "&snapshot=" + (snapshot || "wysiwygdummyclip");
    connector.platform.openUrl(url);
};

Wysiwyg.prototype.capturePlayableAds = function (clipConfig, saveResult) {
    var scene = cleverapps.scenes.getRunningScene();
    scene.removeAllChildren();

    var urls = {};
    PlayableAdsScene.overrideMethodsToCaptureBundles(urls);

    this.loadPrefabResources(clipConfig, function (scenes) {
        this.runClipVariations({
            path: "",
            playableAds: true,
            scenes: scenes
        }, function () {
            if (saveResult) {
                cleverapps.RestClient.post("/wysiwyg/playable/savebundle", {
                    directory: cleverapps.wysiwyg.directory,
                    name: clipConfig.id,
                    content: urls,
                    hash: cleverapps.hashCode(JSON.stringify(this.hierarchy.getClipContent(clipConfig)))
                });
            }
            cleverapps.resolution.setupDesignResolution();
            WysiwygScene.open(cleverapps.wysiwyg);
        }.bind(this));
    }.bind(this));
};

Wysiwyg.prototype.buildPlayable = function (clipConfig) {
    if (cleverapps.isLocalhost() || !cleverapps.config.debugMode) {
        alert("Available only for staging");
        return;
    }

    cleverapps.RestClient.post("/wysiwyg/playable/build", {
        name: clipConfig.id,
        project: cleverapps.config.name,
        force: this.forceBuildZip || 0,
        hash: cleverapps.hashCode(JSON.stringify(this.hierarchy.getClipContent(clipConfig)))
    }, function (msg) {
        this.updateClipStatus();
        cleverapps.notification.create("BuildZip completed. " + msg);
    }.bind(this), function (msg) {
        var response = msg && msg.response && msg.response ? msg.response : "";
        if (response.indexOf("Time-out") !== -1) {
            setTimeout(function () {
                this.updateClipStatus(function (status) {
                    if (status === Wysiwyg.PLAYABLE_STATUSES.READY) {
                        cleverapps.notification.create("BuildZip completed. Ok");
                    } else {
                        cleverapps.RestClient.post("/wysiwyg/playable/cleanupbuild");
                        cleverapps.notification.create("Try to build later...");
                    }
                });
            }.bind(this), 50000);
        } else if (response.indexOf("another build in progress") !== -1) {
            cleverapps.Notification("BuildZip creation error: " + response);
        } else {
            this.updateClipStatus();
            console.log(msg);
            cleverapps.RestClient.post("/wysiwyg/playable/cleanupbuild");
            cleverapps.notification.create("BuildZip creation error. Try to build later...");
        }
    }.bind(this), {
        timeout: 100000
    });
};

Wysiwyg.prototype.loadPrefabResources = function (selectedItem, callback, sceneIndex) {
    var scenes = [];
    var bundlesToLoad = ["wysiwyg_fonts"];
    var urlsToLoad = [];
    var spinesToPack = [];

    var gameProperties = undefined;

    var getNestedComponents = function (hierarchyItem) {
        var componentResources = this.listComponentResources(hierarchyItem.properties, hierarchyItem.assetName);
        bundlesToLoad = bundlesToLoad.concat(componentResources.bundlesToLoad);
        urlsToLoad = urlsToLoad.concat(componentResources.urlsToLoad);
        spinesToPack = spinesToPack.concat(componentResources.spinesToPack);

        return hierarchyItem.children.filter(function (item) {
            return item.type !== HierarchyItem.TYPE.SCRIPT;
        }).filter(function (item) {
            return this.wysiwygPreview.componentsByIds[item.id];
        }.bind(this)).map(function (item) {
            if (item.properties && item.properties.isGame) {
                gameProperties = item.properties;
            }

            var itemResources = this.listComponentResources(item.properties, item.assetName);
            bundlesToLoad = bundlesToLoad.concat(itemResources.bundlesToLoad);
            urlsToLoad = urlsToLoad.concat(itemResources.urlsToLoad);
            spinesToPack = spinesToPack.concat(itemResources.spinesToPack);
            var component = this.wysiwygPreview.componentsByIds[item.id];
            component.properties = item.properties;
            component.components = getNestedComponents(item);
            var validScriptFileNames = this.assets.scripts.map(function (script) {
                return script.fileName;
            });
            component.scripts = item.children.filter(function (item) {
                return item.type === HierarchyItem.TYPE.SCRIPT
                    && (item.properties.scriptContent || validScriptFileNames.indexOf(item.properties.scriptFileName) !== -1);
            }).map(function (item) {
                var result = cleverapps.clone(item.properties);
                var scriptInfo = item.properties.scriptContent ? this.assets.createScriptData(item.properties.scriptContent)
                    : this.assets.scripts.filter(function (script) {
                        return script.fileName === item.properties.scriptFileName;
                    })[0];
                result.scriptParameters = scriptInfo.parameters;
                result.scriptBody = scriptInfo.exec;
                return result;
            }.bind(this));
            return component;
        }.bind(this));
    }.bind(this);

    switch (selectedItem.type) {
        case HierarchyItem.TYPE.CLIP:
            for (var propertyName in selectedItem.properties) {
                var property = selectedItem.properties[propertyName];
                if (this.googleDriveProvider.hasAsset(property)) {
                    urlsToLoad.push(this.googleDriveProvider.getLink(property));
                }
            }

            selectedItem.children.forEach(function (scene, index) {
                if (sceneIndex === undefined || index === parseInt(sceneIndex)) {
                    scenes.push({
                        id: scene.id,
                        properties: scene.properties,
                        components: getNestedComponents(scene)
                    });
                }
            });
            break;
        case HierarchyItem.TYPE.SCENE:
            scenes.push({
                id: selectedItem.id,
                properties: selectedItem.properties,
                components: getNestedComponents(selectedItem)
            });
            break;
        case HierarchyItem.TYPE.COMPONENT:
            var scene = selectedItem.parentItem;
            while (scene.type !== HierarchyItem.TYPE.SCENE) {
                scene = scene.parentItem;
            }
            scenes.push({
                id: scene.id,
                properties: scene.properties,
                components: getNestedComponents(scene)
            });
            break;
    }

    cleverapps.bundleLoader.loadBundles(cleverapps.unique(bundlesToLoad), {
        onSuccess: function () {
            cleverapps.RestClient.post(
                "/wysiwyg/packSpines",
                spinesToPack,
                function () {
                    var afterLoadBundles = function () {
                        cc.loader.load(cleverapps.unique(urlsToLoad), function () {
                            this.applyAdHoc(gameProperties);
                            callback(scenes);
                        }.bind(this));
                    }.bind(this);

                    if (gameProperties) {
                        this.loadExtraBundles(afterLoadBundles);
                        ResolutionManager.prototype.getSceneSize = function () {
                            var w = Wysiwyg.getValueForResolution(gameProperties.width) || 100;
                            var h = Wysiwyg.getValueForResolution(gameProperties.height) || 100;
                            return cc.size(this.sceneRect.width * w / 100, this.sceneRect.height * h / 100);
                        };
                    } else {
                        afterLoadBundles();
                    }
                }.bind(this),
                function () {
                    cleverapps.notification.create("Pack spines failed");
                },
                {
                    timeout: 30000,
                    ignoreNoRest: true
                }
            );
        }.bind(this)
    });
};

Wysiwyg.prototype.applyAdHoc = function (properties) {
    if (!properties || !properties.adHoc) {
        return;
    }

    properties.adHoc.filter(function (adHoc) {
        return adHoc.override;
    }).forEach(function (override) {
        if (override.image) {
            var url = cleverapps.wysiwyg.googleDriveProvider.getLink(override.override);
            var texture = cc.textureCache.addImage(url);
            bundles[override.bundle].frames[override.image] = new cc.SpriteFrame(texture, cc.rect(0, 0, texture.width, texture.height));
        } else if (override.spine) {
            var spinePath = cleverapps.wysiwyg.googleDriveProvider.getSpinePath(override.override);
            if (spinePath) {
                var currentSpinePath = bundles[override.bundle].jsons[override.spine];
                if (typeof currentSpinePath === "object") {
                    currentSpinePath = currentSpinePath.json;
                }

                var currentSpine = cc.loader.getRes(currentSpinePath);
                var spine = cc.loader.getRes(spinePath + ".json");

                var validateSpines = function (spine, currentSpine) {
                    if (!spine || !currentSpine) {
                        return;
                    }

                    var spineInterface = Object.keys(currentSpine.animations).concat(Object.keys(currentSpine.skins));

                    return !spineInterface.some(function (element) {
                        return !(element in spine.animations) && !(element in spine.skins);
                    });
                };

                if (validateSpines(spine, currentSpine)) {
                    bundles[override.bundle].jsons[override.spine] = {
                        json: spinePath + ".json",
                        atlas: spinePath + ".atlas"
                    };
                } else {
                    cleverapps.notification.create("Incorrect spine");
                }
            }
        }
    });
};

Wysiwyg.prototype.loadExtraBundles = function (callback) {
    if (bundles["extra_" + cleverapps.settings.language]) {
        cleverapps.bundleLoader.loadBundles(["extra_" + cleverapps.settings.language], {
            onSuccess: callback
        });
    } else {
        callback();
    }
};

Wysiwyg.prototype.listComponentResources = function (item, assetName) {
    if (!item || typeof item !== "object") {
        return [];
    }

    var bundlesToLoad = [];
    var urlsToLoad = [];
    var spinesToPack = [];
    if (assetName && window[assetName] && window[assetName].PROPERTIES) {
        bundlesToLoad = window[assetName].PROPERTIES.filter(function (assetProperty) {
            return item[assetProperty.name] && item[assetProperty.name].bundle && bundles[item[assetProperty.name].bundle];
        }).map(function (assetProperty) {
            return item[assetProperty.name].bundle;
        });
    }

    var prepareSpine = function (spine) {
        if (spine && spine.name && this.googleDriveProvider.hasAsset(spine)) {
            var spinePath = this.googleDriveProvider.getSpinePath(spine);

            urlsToLoad.push(spinePath + ".atlas");
            urlsToLoad.push(spinePath + ".png");
            urlsToLoad.push(spinePath + ".json");
            spinesToPack.push({
                folder: spine.folder,
                name: spine.name
            });
        }
    }.bind(this);

    ["spine"].forEach(function (propName) {
        prepareSpine(item[propName]);
    });

    ["image", "sound"].forEach(function (propName) {
        if (item[propName] && item[propName].name && this.googleDriveProvider.hasAsset(item[propName])) {
            urlsToLoad.push(this.googleDriveProvider.getLink(item[propName]));
        }
    }.bind(this));

    if (assetName && window[assetName]) {
        if (window[assetName].BUNDLES) {
            window[assetName].BUNDLES.forEach(function (bundleName) {
                bundlesToLoad.push(bundleName);
            });
        }
    }

    if (assetName === "AdsGame" && item.adHoc) {
        item.adHoc.forEach(function (adHoc) {
            if (adHoc.image && adHoc.override && this.googleDriveProvider.hasAsset(adHoc.override)) {
                urlsToLoad.push(this.googleDriveProvider.getLink(adHoc.override));
            } else if (adHoc.spine) {
                prepareSpine(adHoc.override);
            }
        }.bind(this));
    }

    if (item.snapshot) {
        urlsToLoad.push(cleverapps.snapshots.getUrlById(item.snapshot));
    }

    var bg = Wysiwyg.getValueForResolution(item.bg);
    if (this.googleDriveProvider.hasAsset(bg)) {
        urlsToLoad.push(this.googleDriveProvider.getLink(bg));
    }

    return {
        bundlesToLoad: bundlesToLoad,
        urlsToLoad: cleverapps.unique(urlsToLoad.filter(Boolean)),
        spinesToPack: spinesToPack
    };
};

Wysiwyg.prototype.updateClipStatus = function (callback) {
    if (!this.hierarchy.selectedItem || this.hierarchy.selectedItem.type === HierarchyItem.TYPE.DIRECTORY) {
        return;
    }

    if (this.playable) {
        cleverapps.RestClient.post("/wysiwyg/playable/status", {
            name: this.hierarchy.getSelectedClip().id,
            directory: cleverapps.wysiwyg.directory,
            hash: cleverapps.hashCode(JSON.stringify(this.hierarchy.getClipContent(this.hierarchy.getSelectedClip())))
        }, function (data) {
            var statusText = "";

            if (data.status === Wysiwyg.PLAYABLE_STATUSES.NO_BUNDLE) {
                statusText = "Bundle not found or dirty";
            } else if (data.status === Wysiwyg.PLAYABLE_STATUSES.NO_ZIP) {
                statusText = "Playable zip not found";
            } if (data.status === Wysiwyg.PLAYABLE_STATUSES.READY) {
                statusText = data.url;
            }

            this.wysiwygMenubar.updateClipStatus(data.status, statusText);

            if (callback) {
                callback(data.status, statusText);
            }
        }.bind(this));
    }
};

Wysiwyg.prototype.loadClipFromUrlParams = function (callback) {
    var params = cleverapps.getRequestParameters(location.hash);
    if (!cleverapps.git) {
        cleverapps.git = new GitManager();
        cleverapps.git.load();
    }

    this.hierarchy.loadClip(params.clipId, function () {
        var clip = this.hierarchy.getSelectedClip();

        this.loadPrefabResources(
            clip,
            function (scenes) {
                cleverapps.config.wysiwygMode = true;
                cleverapps.config.nologin = true;
                cleverapps.Notification.prototype.create = function () {};
                Forces.prototype.isAvailable = function () {
                    return false;
                };
                Toolbar.prototype.anyItemWithForce = function () {};
                MissionManager.prototype.showStartWindow = function () {};
                Subscription.prototype.needDisplayWindow = function () {};
                BannerAd.prototype.canShow = function () {
                    return false;
                };

                cleverapps.UI.inflateToBoundaries = cleverapps.extendFunc(cleverapps.UI.inflateToBoundaries, function () {
                    var scene = cleverapps.scenes.getRunningScene();
                    var pos = scene.getPosition();
                    var scale = scene.getScale();
                    scene.setPosition(0, 0);
                    scene.setScale(1);

                    this._super.apply(this, arguments);

                    scene.setPositionRound(pos);
                    scene.setScale(scale);
                });

                var resolution = params.res || clip.properties.resolutions[0];
                if (!resolution) {
                    resolution = clip.properties.resolutions.filter(function (res) {
                        return Wysiwyg.VIDEO_SIZES[res];
                    })[0] || Wysiwyg.VIDEO_SIZES.portrait_1080x1920;
                }

                this.wysiwygPreview.selectedResolution = resolution;
                var options = {
                    clipConfig: clip,
                    scenes: scenes,
                    language: params.lang || "en",
                    resolutionName: resolution,
                    download: params.download,
                    preview: params.preview
                };

                cleverapps.prefabPlayer = new PrefabPlayer(options);

                clip.children.map(function (scene) {
                    var flags = this.hierarchy.getSceneFlags(scene);
                    if (flags) {
                        Object.keys(cleverapps.gameModes).forEach(function (flagName) {
                            cleverapps.gameModes[flagName] = flags.indexOf(flagName) !== -1;
                        });
                    }
                }.bind(this));

                callback();
            }.bind(this),
            params.scene
        );
    }.bind(this));
};

Wysiwyg.BeforeImportSnapshot = function (snapshotId) {
    if (snapshotId === "wysiwygdummyclip") {
        cleverapps.Plot.onStartup = function (f, returnClassOnly) {
            if (returnClassOnly) {
                return cleverapps.FixedWidthScene;
            }

            cleverapps.scenes.replaceScene(new cleverapps.FixedWidthScene(), function () {
                cleverapps.prefabPlayer.run();
                f();
            });
        };
        return undefined;
    }

    return snapshotId;
};

Wysiwyg.LANGUAGES = ["en", "ru", "de", "es", "fr", "it", "ja", "nl", "pt", "ar", "ko", "tr", "zh", "pl", "lv"];

Wysiwyg.EVENTS = {
    GAME_STARTED: "gameStarted",
    GAME_FINISHED: "gameFinished",
    PROGRESS_CHANGED: "progressChanged",
    SCENE_COMPLETED: "sceneCompleted",
    SCENE_STARTED: "sceneStarted",
    PUZZLE_SHOW_UP: "puzzleShowUp",
    PUZZLE_STARTED: "puzzleStarted",
    PUZZLE_FINISHED: "puzzleFinished",
    PREVIEW: "preview"
};

if (cleverapps.config.type === "merge") {
    Object.assign(Wysiwyg.EVENTS, {
        LIVES_FEAST_SIDEBAR_ICON_CLICKED: "livesFeastSideBarIconClicked",
        SOFT_FEAST_SIDEBAR_ICON_CLICKED: "softFeastSideBarIconClicked",
        OPEN_FOG: "openFog",
        HIGHLIGHT_MERGE: "highlightMerge",
        OPENING_CHEST: "openingChest",
        HERO_CREATED: "heroCreated",
        UNIT_MERGED: "unitMerged",
        HARVEST: "harvest",
        MINE: "mine"
    });
}

if (cleverapps.config.type === "match3") {
    Object.assign(Wysiwyg.EVENTS, {
        INC_GOAL: "incGoal"
    });
}

Wysiwyg.PLAYABLE_STATUSES = {
    NO_BUNDLE: "noBundle",
    NO_ZIP: "noZip",
    READY: "ready"
};

Wysiwyg.DEFAULT_DIRECTORY = "wysiwyg";
Wysiwyg.NOT_SELECTED = "not selected";
