/**
 * Created by spepa on 10/05/20
 */

var PaintBrush = function (photos) {
    this.photos = photos;
    this.side = this.determineSide();
    this.addPaint();
    this.gatherItems();
};

PaintBrush.prototype.isInPaintArea = function (object, side) {
    var paintArea = cleverapps.styles.PaintBrush;
    var pos = this.getAreaPos(side);

    var areaRect = cc.rect(pos.x - paintArea.width / 2, pos.y - paintArea.height / 2, paintArea.width, paintArea.height);
    var objectRect = cc.rect(object.x - object.width / 2, object.y - object.height / 2, object.width, object.height);
    return cc.rectIntersectsRect(objectRect, areaRect);
};

PaintBrush.prototype.cloversToGather = function () {
    var cloversToGather = [];

    this.photos.clovers.forEach(function (clover) {
        if (this.isInPaintArea(clover)) {
            cloversToGather.push(clover);
        }
    }, this);

    return cloversToGather.sort(function (a, b) {
        return a.y < b.y ? 1 : -1;
    });
};

PaintBrush.prototype.getAreasToOpen = function () {
    return this.areasToOpen.sort(function (a, b) {
        return a.y < b.y ? 1 : -1;
    });
};

PaintBrush.prototype.determineSide = function () {
    var left = [];
    var right = [];

    this.photos.differences.getUndiscoveredAreas().forEach(function (area) {
        var areaData = PhotoViews.FrameSizeAndPos(area);
        if (this.isInPaintArea(areaData, PaintBrush.RIGHT_SIDE)) {
            right.push(area);
        }
        if (this.isInPaintArea(areaData, PaintBrush.LEFT_SIDE)) {
            left.push(area);
        }
    }, this);

    if (left.length < right.length) {
        this.areasToOpen = left;
        return PaintBrush.LEFT_SIDE;
    } 
    this.areasToOpen = right;
    return PaintBrush.RIGHT_SIDE;
};

PaintBrush.prototype.getAreaPos = function (side, areaWidth) {
    var photoSize = this.photos.getPhotoSize();
    areaWidth = areaWidth || cleverapps.styles.PaintBrush.width;
    side = side !== undefined ? side : this.side;

    if (side === PaintBrush.RIGHT_SIDE) {
        return cc.p(photoSize.width - areaWidth / 2, photoSize.height / 2);
    } 
    return cc.p(areaWidth / 2, photoSize.height / 2);
};

PaintBrush.prototype.gatherItems = function () {
    var clovers = this.cloversToGather();
    var cloversStep = PaintBrush.LOOPE_TIME / clovers.length;
    var areas = this.getAreasToOpen();
    var areasStep = PaintBrush.LOOPE_TIME / areas.length;

    clovers.forEach(function (clover, ind) {
        clover.runAction(new cc.Sequence(
            new cc.DelayTime(cloversStep * (ind + 1)),
            new cc.CallFunc(function () {
                clover.cloverFound();
            })
        ));
    });

    areas.forEach(function (area, ind) {
        this.photos.runAction(new cc.Sequence(
            new cc.DelayTime(areasStep * (ind + 1)),
            new cc.CallFunc(function () {
                this.photos.differences.discover(area);
            }.bind(this))
        ));
    }, this);
};

PaintBrush.prototype.addPaint = function () {
    this.photos.runAction(new cc.Sequence(
        new cc.DelayTime(2),
        new cc.CallFunc(function () {
            cleverapps.audio.playSound(bundles.game.urls.brush_paint);
        })
    ));

    [this.photos.imageA, this.photos.imageB].forEach(function (parent) {
        var paintArea = new cc.Node();
        paintArea.setAnchorPoint(0.5, 0.5);
        paintArea.setPositionRound(this.getAreaPos());
        paintArea.setContentSize2(cleverapps.styles.PaintBrush);
        parent.addChild(paintArea, 2);

        var paint = new cleverapps.Spine(bundles.game.jsons.brush_paint_json);
        paintArea.addChild(paint);
        paint.setPositionRound(paintArea.width / 2, paintArea.height / 2);

        var blockPhotoClickHandler = cleverapps.UI.onClick(paintArea, function () {}, {});
        paintArea.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                paint.setAnimation(0, this.side === PaintBrush.LEFT_SIDE ? "brush" : "brush2", false);
            }.bind(this)),
            new cc.DelayTime(2.3),
            new cc.CallFunc(function () {
                blockPhotoClickHandler.remove();
                cleverapps.tooltipManager.create(paintArea, {
                    text: Messages.get("PaintAreaTooltip"),
                    location: cleverapps.UI.Tooltip.LOCATION_ABOVE
                });
            })
        ));
    }, this);
};

PaintBrush.LEFT_SIDE = 0;
PaintBrush.RIGHT_SIDE = 1;
PaintBrush.LOOPE_TIME = 1.5;

cleverapps.styles.PaintBrush = {
    width: 450,
    height: 600
};