/**
 * Created by vladislav on 03/05/2023
 */

cleverapps.override(cleverapps.Tool.game, {
    pass_level: function () {
        while (Game.currentGame && Game.currentGame.getPercentOfCompletion() !== 1) {
            Game.currentGame.discoverDebug({
                player: true
            });
        }
    }
});