/**
 * Created by andrey on 02.08.2022
 */

var BeforeWinAction = function () {
    var scene = cleverapps.scenes.getRunningScene();
    if (scene.startMusicAction && !scene.startMusicAction.isDone()) {
        scene.stopAction(scene.startMusicAction);
        delete scene.startMusicAction;
    }
    cleverapps.audio.fadeOut();
    this.level.isPassed = (this.outcome === GameBase.OUTCOME_VICTORY);

    cleverapps.placements.run(Placements.AFTER_GAME_FINISH);

    var outcome = this.outcome;
    if (outcome === GameBase.OUTCOME_VICTORY || outcome === GameBase.OUTCOME_GAVEUP) {
        this.giveLife();
    }

    if (this.level.isRegular() && cleverapps.isKnockoutGame()) {
        cleverapps.meta.getRumble().moveNextRound(outcome);
    }

    this.addBasicReward();

    if (this.getMode() === GameBase.MODE_HIGHSCORE) {
        cleverapps.highscore.gamePlayed(outcome, this);
    }

    if (this.level.isRegular()) {
        cleverapps.meta.getMainObject().gamePlayed(outcome, this);
    }

    if (cleverapps.meta.focus === "KeypadControl") {
        this.keypad.reset();
    }

    var lantern = Lantern.Get();
    if (lantern && lantern.isActive(this.level)) {
        lantern.onChangeOutcome(outcome);
    }

    if (outcome === GameBase.OUTCOME_VICTORY) {
        BeforeWinVictoryAction.call(this);
    }
};

var BeforeWinVictoryAction = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.LEVEL_PASSED_TIME, {
        hash: this.level.hash,
        passedTime: this.timer.getTime()
    });

    if (this.level.isRegular() || this.level.isBonusWorldLevel()) {
        if (this.leftMoves !== undefined) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.LEVEL_LEFT_MOVES, {
                hash: this.level.hash,
                leftMoves: this.leftMoves
            });
        }

        var levelFinish = true;
        if (cleverapps.config.type === "board") {
            levelFinish = this.timer.getTime() <= cleverapps.parseInterval("5 minutes");
        }
        if (levelFinish) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.LEVEL_FINISH, {
                hash: this.level.hash
            });
            if (cleverapps.ABTest.HAND_DIFFICULTY()) {
                var eventName = "level_finished";
                if (cleverapps.user.registered > TriPeaks.START_HAND_AB_TEST_TIME) {
                    eventName += "_new";
                }
                cleverapps.abTest.logEvent(cleverapps.ABTest.Tests.handdiff, eventName);
            }
        }

        if (this.level.isNew() || this.level.isBonusWorldLevel()) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.UNIQUE_FINISH, {
                hash: this.level.hash
            });
        }
    }

    var isCurrentLevelOld = this.level.isCurrentLevel();

    this.boatswain.level = this.level = this.level.clone();

    this.level.isCurrentLevel = function () {
        return isCurrentLevelOld;
    };

    if (this.level.isDailyLevel()) {
        cleverapps.dailyLevel.onWin(this.level);
    }

    if (this.level.isBonusWorldLevel()) {
        cleverapps.missionManager.dispatchEvent(Mission.TYPE_BONUS_WORLD, { level: this.level });
    }

    cleverapps.missionManager.refreshTeaser();

    if (cleverapps.config.type === "board") {
        BeforeWinVictoryWordAction.call(this);
    }

    if (this.hasDanger()) {
        this.dangerComponent.onWin();
    }

    this.eraseSave();
};

var BeforeWinVictoryWordAction = function () {
    levels.gameHints.reset();

    if (this.level.isCurrentLevel()) {
        if (this.tournament) {
            var place = this.competition.calcPlace() + 1;
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.TOURNAMENT_LEVEL_PLACE + "_" + place);
        }
    }

    if (this.competition && this.rewards.mission) {
        this.competition.onAnimateResults(this.rewards.mission.amount);
    }
};