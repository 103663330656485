/**
 * Created by iamso on 16.07.20.
 */

var DiscoverButtonBooster = BaseButtonBooster.extend({
    ctor: function (booster) {
        var styles = cleverapps.styles.DiscoverButtonBooster;
        styles.mirrorArrowY = cleverapps.resolution.mode !== cleverapps.WideMode.SQUARE;

        this._super({
            booster: booster,
            styles: styles
        });
    },

    getLayoutOptions: function () {
        return {
            direction: this.styles.content.direction[cleverapps.resolution.mode],
            margin: this.styles.content.margin[cleverapps.resolution.mode]
        };
    },

    updateSize: function () {
        this.icon.setScale2(this.styles.content.iconScale);

        this._super();
    },

    onPressed: function () {
        if (cleverapps.forces.isRunningForce(Forces.COOLDOWN_FORCE)) {
            cleverapps.forces.closeRunningForce();
        }

        this._super.apply(this, arguments);
    },

    toggleArrow: function (on) {
        if (cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_REGULAR) {
            this._super.apply(this, arguments);
        } else if (!on || this.booster.canExecute()) {
            this._super.apply(this, arguments);
        }
    },

    toggleTint: function (on) {
        if (cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_REGULAR) {
            this._super.apply(this, arguments);
        } else if (!on || this.booster.canExecute()) {
            this._super.apply(this, arguments);
        }
    },

    createIcon: function () {
        var animation = new cleverapps.Spine(bundles.game.jsons.discovery_icon_json);
        animation.setAnimation(0, "idle", true);
        return animation;
    },

    getSize: function () {
        return this.styles.size[cleverapps.resolution.mode];
    },

    getFreeTextWidth: function () {
        var styles = this.styles.content.freeBlock[cleverapps.resolution.mode];

        return styles.textWidth;
    },

    _innerUpdateBgContent: function () {
        var bright = this.bright && !this.disabled;
        this._super();
        if (cleverapps.forces.isRunningForce(this.booster.force)) {
            return;
        }
        if (!bright) {
            [this.background, this.icon].forEach(function (item) {
                cleverapps.UI.convertToGrayScale(item.spine || item);
            });
        }
    }

});

cleverapps.styles.DiscoverButtonBooster = {
    size: [{ width: 240, height: 100 }, { width: 180, height: 160 }, { width: 240, height: 100 }],
    zOrder: 0,

    type: cleverapps.styles.UI.Button.Images.button_blue,

    phone: {
        scale: 0.8
    },

    content: {
        freeBlock: [{ textWidth: 110 }, { textWidth: 120 }, { textWidth: 110 }],
        iconScale: [0.55, 0.7, 0.55],
        margin: [0, -12, 0],
        direction: [cleverapps.UI.HORIZONTAL, cleverapps.UI.VERTICAL, cleverapps.UI.HORIZONTAL],
        layout: [
            { x: { align: "center", dx: -5 }, y: { align: "center", dy: 0 } },
            { x: { align: "center", dx: 0 }, y: { align: "center", dy: 7 } },
            { x: { align: "center", dx: -5 }, y: { align: "center", dy: 0 } }
        ]
    }
};