/**
 * Created by andrey on 01.08.2022
 */

var HideAnimations = function (f) {
    new ActionPlayer([
        function (f) {
            if (this.game.outcome === GameBase.OUTCOME_VICTORY) {
                this.photos.complete(f);
            } else {
                if (this.game.discoverBooster) {
                    this.game.discoverBooster.stopHint();
                }

                f();
            }
        }.bind(this),

        function (f) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(1.5),
                new cc.CallFunc(function () {
                    this.playersView.hide();
                    this.photos.hide();
                }.bind(this)),
                new cc.DelayTime(1),
                new cc.CallFunc(f)
            ));
        }.bind(this)
    ]).play(f);
};