/**
 * Created by andrey on 04.07.17.
 */

cleverapps.CrossPromo = function () {
    var saveInfo = this.load();

    if (saveInfo) {
        this.processed = saveInfo.processed;
    } else {
        this.reset();
    }

    this.remoteConfig = new RemoteConfig(bundles.crosspromo.jsons.crosspromo_json.getJson());
};

cleverapps.CrossPromo.prototype.reset = function () {
    this.processed = [];
    this.save();
};

cleverapps.CrossPromo.prototype.getFirstAvailable = function () {
    var result = undefined;
    this.listPromotions().forEach(function (promotion) {
        if (result || this.processed.indexOf(promotion.id) !== -1) {
            return;
        }

        result = promotion;
    }, this);

    if (result !== undefined) {
        return result;
    }

    if (this.processed.length > 0) {
        this.processed = [];
        this.save();
        return this.getFirstAvailable();
    }

    return undefined;
};

cleverapps.CrossPromo.prototype.getPlatformConfig = function (config) {
    var source = connector.info.source;

    switch (source) {
        case "macos":
            return config.ios;
        case "sp_mbga":
            return config.mbga;
        default:
            return config[source];
    }
};

cleverapps.CrossPromo.prototype.listPromotions = function () {
    var promotions = [];

    var json = this.remoteConfig.getConfig();
    var priorities = json.priorities;

    var projects = Object.keys(priorities).sort(function (key1, key2) {
        return (priorities[key2] || 0) - (priorities[key1] || 0);
    });

    projects.forEach(function (game) {
        if (game === cleverapps.config.name) {
            return;
        }

        var config = json[game];

        var platformConfig = this.getPlatformConfig(config);
        if (!platformConfig || !cleverapps.config.debugMode && platformConfig.debug) {
            return;
        }

        var bundleNameEn = "crosspromo_" + game + "_" + cc.sys.LANGUAGE_ENGLISH;
        if (!bundles[bundleNameEn]) {
            return;
        }
        var bundleName = "crosspromo_" + game + "_" + cleverapps.settings.language;

        var promo = {
            id: game,
            title: "" + game + "PromoTitle",
            description: "" + game + "Promo",
            bundleName: bundles[bundleName] ? bundleName : bundleNameEn,
            link: connector.utils.guessPlatformURL({ config: config }),
            payload: undefined
        };

        if (connector.info.source === "instant") {
            var projectBusiness = cleverapps.config.instant && cleverapps.config.instant.business || cleverapps.config.business;
            var promoBusiness = config.instant && config.instant.business || config.business;

            if (projectBusiness !== promoBusiness) {
                return;
            }
        }

        if (connector.info.source === "instant") {
            promo.link = config.instant && config.instant.appId;
            promo.payload = { channel: cleverapps.config.name };
        }

        if (connector.info.source === "msstart") {
            promo.link = config.msstart && config.msstart.appId;
            promo.payload = { channel: cleverapps.config.name };
        }

        if (!promo.link || connector.info.source === "gdcom") {
            return;
        }

        if (connector.info.source === "facebook") {
            promo.link += "?channel=" + cleverapps.config.name;
        }
        if (connector.info.source === "android") {
            promo.link += "&utm_source=" + cleverapps.config.name;
        }

        promotions.push(promo);
    }.bind(this));

    return promotions;
};

cleverapps.CrossPromo.prototype.isReady = function () {
    if (!cleverapps.user.checkAvailable(cleverapps.CrossPromo.AVAILABLE)) {
        return false;
    }

    return this.getFirstAvailable() !== undefined;
};

cleverapps.CrossPromo.prototype.setProcessed = function (promotion) {
    this.processed.push(promotion.id);
    this.save();
};

cleverapps.CrossPromo.prototype.load = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.PROMOTION);
};

cleverapps.CrossPromo.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.PROMOTION, {
        processed: this.processed
    });
};

cleverapps.CrossPromo.ShowFirstAvailable = function () {
    var promotion = cleverapps.crossPromo.getFirstAvailable();

    new CrossPromoWindow(promotion);
};

cleverapps.CrossPromo.BONUS = 10;

cleverapps.CrossPromo.AVAILABLE = {
    level: 3.33
};

if (cleverapps.config.type === "merge") {
    cleverapps.CrossPromo.AVAILABLE = {
        level: 8.2
    };
}