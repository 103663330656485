/**
 * Created by Denis Kuzin on 29 january 2021
 */

KnockoutTutorial.STEPS = [
    {
        isShown: function () {
            return cleverapps.forces.isShown(Forces.KNOCKOUT_PLAYERS.id);
        },
        isAvailable: function () {
            return cleverapps.environment.isGameScene() && cleverapps.isRumble();
        },
        show: function (f) {
            cleverapps.knockoutTutorial.onPlayersForceListener(f);
        }
    },
    {
        isShown: function () {
            return cleverapps.forces.isShown(Forces.KEYPAD.id);
        },
        control: "finger_tip",
        isAvailable: function () {
            return cleverapps.environment.isGameScene();
        },
        show: function (f) {
            if (Game.currentGame) {
                cleverapps.knockoutTutorial.onFirstMoveForceListener(f);
            } else {
                f();
            }
        }
    },
    {
        isShown: function () {
            return cleverapps.forces.isShown(Forces.METHA_PLAY_BUTTON.id);
        },
        isAvailable: function () {
            return cleverapps.environment.isMainScene();
        },
        show: function (f) {
            cleverapps.knockoutTutorial.showPlayButtonForce(f);
        }
    }
];